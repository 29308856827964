<template>
    <div>
      <div>
        <el-row class="bgW">
        <el-col :span="20">
          <!--检索条件-->
          <el-form class="search" :inline="true" label-suffix="：" size="mini" :model="search">
            
             <el-form-item label="日期">
              <el-date-picker
                v-model="search.start_time"
                type="date"
                class="w130"
                placeholder="开始日期"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
              <el-date-picker
                v-model="search.end_time"
                type="date"
                class="w130"
                placeholder="结束日期"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
            
          </el-form>
        </el-col>
        <el-col :span="4" class="btnBox">
          <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
          <!-- <el-button type="primary" class="mb10" size="mini"  :loading="exportS" @click="exportExcel">导出数据</el-button> -->
        </el-col>
      </el-row>
  
        <!--列表-->
        <el-row>
          <el-col :span="24">
            <el-table
              :data="list"
              size="small"
              border
              v-loading="loading"
              :cell-style="$style.cellStyle"
              :header-cell-style="$style.rowClass"
            >
            <ElTableColumn label="客服">
              <template slot-scope="{ row }">
                <div
                class="hand"
                @click="clickBtn('service',row)"
                >{{row.name}}</div>
              </template>
            </ElTableColumn>
            <ElTableColumn label="预约看诊" prop="reservationConsultation" />
            <ElTableColumn label="预约到院" prop="reservationReach" />
            <ElTableColumn label="预约总数" prop="reservationSum" />
            <ElTableColumn label="看诊数">
              <template slot-scope="{ row }">
                <div
                class="hand"
                @click="clickBtn('physician1',row)"
                >{{row.realReservationConsultation}}</div>
              </template>
            </ElTableColumn>
            <ElTableColumn label="到院数">
              <template slot-scope="{ row }">
                <div
                class="hand"
                @click="clickBtn('physician2',row)"
                >{{row.realReservationReach}}</div>
              </template>
            </ElTableColumn>
            <!-- <ElTableColumn label="看诊数" prop="realReservationConsultation" />
            <ElTableColumn label="到院数" prop="realReservationReach" /> -->
            <ElTableColumn label="看诊成交" prop="dealConsultation" />
            <ElTableColumn label="到院成交" prop="dealReach" />
            <ElTableColumn label="线上成交率" prop="reservationConsultationRate" />
            <ElTableColumn label="到院成交率" prop="reservationReachRate" />
            <ElTableColumn label="总成交率" prop="sumRate" />
            <ElTableColumn label="线上新单" prop="dealConsultationNew" />
            <ElTableColumn label="到院新单" prop="dealReachNew" />
            <ElTableColumn label="新单金额" prop="newSum" />
            <ElTableColumn label="线上补单" prop="dealConsultationOld" />
            <ElTableColumn label="到院补单" prop="dealReachOld" />
            <ElTableColumn label="补单金额" prop="oldSum" />
            <ElTableColumn label="总成交金额" prop="sum" />
              <!-- <ElTableColumn label="市场" prop="market_name" />
              <ElTableColumn label="店面" prop="shop_name" />
              <ElTableColumn label="店面" prop="shop_name" />
  
              <ElTableColumn label="线上进店">
                <el-table-column v-for="(item, index) in online" :key="index"  :label="item.name" >
                   <template slot-scope="{ row }">
                    <div class="hand" @click="clickOnline(row,row.online[index].id)">{{row.online[index].num}}</div>
                  </template>
                </el-table-column>
              </ElTableColumn>
              <ElTableColumn label="线下进店">
                <el-table-column v-for="(item, index) in offline" :key="index"  :label="item.name">
                   <template slot-scope="{ row }">
                    <div  >{{row.offline[index].num}}</div>
                  </template>
                </el-table-column>
              </ElTableColumn>
              <ElTableColumn label="总进店数量" prop="all" />
              <ElTableColumn label="档案数量" prop="daall" />
              <ElTableColumn label="网络进店率" prop="wljoin" /> -->
            </el-table>
            <!-- <pagination
              class="page tc mt10"
              :total="page.total"
              :page.sync="page.page"
              :limit.sync="page.limit"
              @pagination="getList"
            /> -->
          </el-col>
        </el-row>
      </div>
    </div>
  </template>
  <script>
  import { getLastDay } from '@/utils';
  import pagination from "@/components/Pagination";
  import { getYellowV } from "@/api/statistics";
  class Page {
    total = 0;
    page = 1;
    limit = 10;
  }
  class Search {
    start_time = "";
    end_time = "";
  }
  export default {
    name: "OrderList",
    components: {
      pagination
    },
    data() {
      return {
        search: new Search(), // 检索条件
        page: new Page(), // 分页
        list: [], // 数据展示
        loading:false,
        exportS: false,
      };
    },
    mounted() {
      let year = new Date().getFullYear()
      let month = new Date().getMonth() + 1
      if(month.toString().length == 1){
        month = '0'+ month
      }
      let day = getLastDay(year,month)
      if(day.toString().length == 1){
        day = '0'+ day
      }
      this.search.start_time = year+'-'+ month + '-01' 
      this.search.end_time = year+'-'+ month + '-' + day
      this.getList('search');
      
    },
    methods: {
      // 获取列表
      getList(option) {
        this.loading = true
        if (option === "search") this.page = new Page();
        getYellowV({ ...this.search}).then(res => {
          console.log(res);
          this.list = res.data;
          this.loading = false;
        });
      },
      // 导出
      // exportExcel() {
      //   this.exportS = true
      //   getYellowV({...this.search,export:1}).then(res => {
      //     window.open(res.data.url);
      //     this.exportS = false
      //   });
      // },
      // 跳转事件
      clickBtn(type,row){
        // this.$router.push("./entranceShop?shop_id="+row.shop_id + '&source_id='+id  + '&start_time='+this.search.start_time + '&end_time='+this.search.end_time);
        switch(type)
        {
          case 'service':{
            this.$router.push({ name: "黄v客服数据", query:{id:row.id,start_time:this.search.start_time,end_time:this.search.end_time} });
            break;
            // this.$router.push("./YellowVService?id="+row.id + '&start_time='+this.search.start_time + '&end_time='+this.search.end_time);
          }
          case 'physician1':{
            this.$router.push({ name: "黄v客服护理师数据", query:{id:row.id,start_time:this.search.start_time,end_time:this.search.end_time,consultation_status:1} });
            break;
          }
          case 'physician2':{
            this.$router.push({ name: "黄v客服护理师数据", query:{id:row.id,start_time:this.search.start_time,end_time:this.search.end_time,consultation_status:2} });
            break;
          }
        }
      },
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .hand{
    cursor:pointer;
  }
  .btnBox{
    text-align: right;
  }
  </style>
  